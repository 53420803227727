/* This is the vendor import that contains items needed for most of the "main" files to build out correctly
it can be loaded multiple times because most of these files should not bulk up the final built file.
*/
/* Can't use this because we're using root-defined colors

@function set-secondary-font-color($color) {
	@if ($color == #fff) {
		@return #a2a2a2;
	} @else if (lightness($color) > 50) {
		@return darken($color, 28%)
	} @else {
		@return lighten($color, 20%);
	}
}
 */
/* creates styles for the error pages, given that the pages have the correct components/classnames */
.errorpage-message-con {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.errorpage-message-con:before {
  content: "";
  display: block;
  width: 100%;
  height: 120px;
  background-position: top center;
  background-size: 288px;
  background-repeat: no-repeat;
  background-image: url(/_design/admin/default/cartoon-peregrine-headtilt.png);
}
.errorpage-message-con.sorry:before {
  background-image: url(/_design/admin/default/cartoon-peregrine-sad.png);
}
.errorpage-message-con.time:before {
  background-image: url(/_design/admin/default/cartoon-peregrine-sleeping.png);
}
.errorpage-message-con.hardhat:before {
  background-image: url(/_design/admin/default/cartoon-peregrine-hardhat.png);
}
.errorpage-message-con .errorpage-message {
  text-align: center;
  padding: 0 2rem 2rem;
  margin: 0 auto;
  max-width: 400px;
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
}
.errorpage-message-con .errorpage-message h2 {
  border-bottom: 1px solid gray;
  padding-bottom: 1.5rem;
}
.errorpage-message-con:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/_design/admin/default/proteus-engage-watermarkbg.jpg);
  z-index: -1;
}